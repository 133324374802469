import React from 'react'
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Marquee from "react-fast-marquee";
const AppBar = () => {
  return (
    <>
      <>
        <div className='mark-bg'>
          <div className='d-lg-block d-none'>
            <Container>
              <Row className='py-2'>
                <Col lg="4" xs="4" sm="4" className='align-self-center d-lg-block d-none'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <img src={require('../../assets/images/gpay.png')} className='img-fluid gpay' alt='gpay' />
                    </div>
                    <div className='text-white'>
                      <h5 className='bold mt-2 mx-2'>8754179585</h5>
                    </div>
                  </div>
                </Col>
                <Col lg='8' className='align-self-center text-white'>
                  <Marquee pauseOnHover={true} direction="left" speed={50} delay={9}>
                    |  Whatsapp : +91 78069 79034 | Alter No : +91 87541 79585 | Alter No : +91 78069 68159 | Email :  sugunacrackers@gmail.com
                  </Marquee>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='d-lg-none d-block py-3'>
            <Container>
              <Row>
                <Col xs="4" sm="4">
                  <a href="https://wa.me/917806979034?text=Hello Suguna crackers">
                    <div className='mob-icon mx-auto'>
                      <img src={require('../../assets/images/whatsappicon.webp')} className='img-fluid' alt='product name' />
                    </div>
                  </a>

                </Col>
                <Col xs="4" sm="4">
                  <a href='tel:+917806979034'>
                    <div className='mob-icon mx-auto'>
                      <img src={require('../../assets/images/callicon.webp')} className='img-fluid' alt='product name' />
                    </div>
                  </a>

                </Col>
                <Col xs="4" sm="4">
                  <a href='https://maps.app.goo.gl/qir821PWSGWdPsTA6' alt="">
                    <div className='mob-icon mx-auto'>
                      <img src={require('../../assets/images/googlelocation.png')} className='img-fluid' alt='product name' />
                    </div>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
      <>
        <div className='navv-bg'>
          <Container className='py-3'>
            <Row>
              <Col lg='4' md='12' xs="12">
                <Navbar.Brand href="/">
                  <div className='logo mx-auto'>
                    <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
                  </div>
                </Navbar.Brand>
              </Col>
              <Col lg='4' className='align-self-center d-lg-block d-none'>
                <div className='d-flex justify-content-center text-white'>
                  <div></div>
                  <div>
                    <h5 className='bold'> Address</h5>
                    <p className='regular'> Byepass Road, Venkatachalapuram, <br />
                      Sattur - 626203
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg='4' className='align-self-center d-lg-block d-none'>
                <div className='d-flex justify-content-center text-white'>
                  <div></div>
                  <div class>
                    <h5 className='bold'> Mobile Number</h5>
                    <div className='regular'>
                      <li>+91 7806979034</li>
                      <li>+91 8754179585</li>
                      <li>+91 7806968159</li>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>

      <>
        <Navbar expand="lg" className="bg-body-tertiary" >
          <Container>
            <div className='mx-auto'>
              <Navbar.Toggle aria-controls="basic-navbar-nav mx-auto text-center py-3" />
            </div>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto text-center py-3">
                <Nav.Link as={NavLink} exact to={"/"} >Home</Nav.Link>
                <Nav.Link as={NavLink} exact to={"/about"} >About</Nav.Link>
                <Nav.Link as={NavLink} exact to={"/products"} >Products</Nav.Link>
                <Nav.Link as={NavLink} exact to={"/safetytips"} >Safety Tips</Nav.Link>
                <Nav.Link as={NavLink} exact to={"/contact"} >Contact</Nav.Link>
                {/* <Nav.Link as={NavLink} exact to={"/safetytips"} activeClassName="active">Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contactus"} activeClassName="active">Contact Us</Nav.Link> */}
              </Nav>
              {/* <Nav.Link as={NavLink} exact to={"/products"} activeClassName="active" className='text-center estimate'>Estimate Now !!!</Nav.Link> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>

    </>
  )
}

export default AppBar