import React from 'react';
import MetaTags from './components/MetaTags';
import AppBar from './components/AppBar'
import { Container,Row,Col } from 'react-bootstrap'
import { PiMapPinSimpleAreaBold } from "react-icons/pi";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import Mark from './components/Mark';
const ContactUs = () => {
  return (
    <div>
       <MetaTags
        title="Contact Us - Suguna Crackers - Whole sale Crackers"
        type="website"
        siteName="sugunacrackers.com"
        url="https://sugunacrackers.com/contactus"
        keywords="Contact Us | Suguna Crackers "
        description="Contact for wholesale Crackers and Retail Crackers"
        revisitAfter="10 Days"
      />
      <AppBar/>
      <img src={require('../assets/images/banner_three.jpg')} className='img-fluid w-100' alt='product name' />
        <Container className='padding'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className='py-3'>
                <div className='bold  text-center'> Contact Us</div>
              </Col>
              <Col lg='6' className='py-3'>
                <Row>
                  <Col lg="12" md="12" className='py-3'>
                    <div className='box'>
                        <div className='box-icon'>
                          <PiMapPinSimpleAreaBold size={40} color={'#000'} />
                        </div>
                        <div className='regular'>
                        Byepass Road, Venkatachalapuram, <br/>
                        Sattur - 626203 
                        </div>
                    </div>
                  </Col>
                  <Col lg="12" className='py-3'>
                    <div className='box'>
                        <div className='box-icon'>
                        <BiPhoneCall size={40} color={'#000'}/>
                        </div>
                        <div>
                        <li>+91 87541 79585</li>
                        <li>+91 7806979034</li>
                        <li>+91 78069 68159</li>
                        </div>
                    </div>
                  </Col>
                  <Col lg="12" className='py-3'>
                    <div className='box'>
                        <div className='box-icon'>
                          <MdOutlineAlternateEmail size={40} color={'#000'}/>
                        </div>
                        <div className='pb-4'>
                          <div>sugunacrackers@gmail.com</div> 
                        </div>
                    </div>
                  </Col>
                </Row>
              </Col>
             
              <Col lg='6' md="6" xs="12" className='py-5'>
                <>
               
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1391.7616394715787!2d77.91035174989369!3d9.377756267835332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cb0b1644a621%3A0xc765568d0a10d200!2sSuguna%20crackers!5e0!3m2!1sen!2sin!4v1725305448221!5m2!1sen!2sin"
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen
                    title="Google Maps Embed of Sivakasi, Tamil Nadu"
                  />
                </>
              </Col>
            
              
            </Row>
        </Container>
    
      
        <>
        <Mark/>
        </>
    </div>
  )
}

export default ContactUs